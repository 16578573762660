<template>
  <section class="transaction--timeline" v-if="timeline">
    <div
      class="wrapper"
      :class="{
        active: item.is_active,
        paid: item.timestamp,
      }"
      v-for="(item, index) in timeline"
      :key="index"
    >
      <div
        class="indicator"
        :class="{
          'active--indicator': index + 1 < timeline.length && timeline[index + 1].is_active,
          'paid--indicator': index + 1 < timeline.length && timeline[index + 1].timestamp,
        }"
      >
        <div class="circle">{{ index + 1 }}</div>
      </div>
      <div class="d-flex justify-space-between align-start">
        <div class="content">
          <div class="mr-4">
            <div class="title--text">{{ item.status_name }}</div>
            <div class="invoice--number">
              {{ `${$t('general.invoiceNumber')}: ${item.invoice_number}` }}
            </div>
            <div class="subtitle">
              {{ item.amount }}
            </div>
            <div class="d-flex align-center">
              <router-link :to="basePath + '/' + item.uuid">
                <v-btn type="button" color="success">
                  {{ $t('billing.openDetail') }}
                </v-btn>
              </router-link>
              <v-btn
                type="button"
                color="success"
                v-if="permissions.includes('EDIT') && !item.timestamp && !item.is_active"
                @click="editBilling(item)"
              >
                {{ $t('billing.edit') }}
              </v-btn>
              <v-icon
                class="delete mr-2"
                v-if="permissions.includes('DELETE') && !item.timestamp && !item.is_active"
                @click="showDeleteDialog(item)"
                >mdi-delete</v-icon
              >
            </div>
          </div>
          <div class="timestamp" v-if="item.timestamp">
            {{ format(new Date(item.timestamp), 'dd MMMM yyyy HH:mm') }}
          </div>
        </div>
        <div class="action--message">
          {{ item.action_message }}
        </div>
      </div>
    </div>
    <v-row class="justify-center mt-6 mb-4" v-if="permissions.includes('ADD')" @click="addBilling">
      <v-btn type="button" color="success">
        <v-icon class="mr-2">mdi-plus</v-icon>
        {{ $t('billing.add') }}
      </v-btn>
    </v-row>
    <v-dialog v-model="showDelete" max-width="300">
      <v-card>
        <v-card-title>
          {{ $t('delete.title') }}
          <v-spacer />
          <v-icon aria-label="Close" @click="showDelete = false"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn color="error" text @click="showDelete = false">
            {{ $t('delete.no') }}
          </v-btn>
          <v-btn color="primary" text @click="deleteBilling">
            {{ $t('delete.yes') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { format } from 'date-fns';
import { mapState } from 'vuex';
export default {
  props: ['timeline', 'permissions'],
  data() {
    return {
      format,
      showDelete: false,
      selectedBilling: null,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.billing.basePath,
      transactionDetail: (state) => state.transaction.detail,
    }),
    isLastItem(itemList, index) {
      if (itemList && index < itemList.length - 1) {
        if (!itemList[index + 1].timestamp) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    async addBilling() {
      this.$store.commit('billing/form/SET_FORM_TYPE', 'ADD');
      await this.$store.dispatch('billing/form/restoreInitialState');
      await this.$store.dispatch('billing/form/applyBaseData', this.transactionDetail);
      this.$store.commit('billing/form/SET_TRANSACTION_UUID', this.transactionDetail.uuid);
      this.$modal.show('modal-billing', {
        billing: this.detail,
        fromBillingDetail: false,
        dispatchAfterString: 'transaction/getDetailInitData',
      });
    },
    async editBilling(billing) {
      this.$store.commit('billing/form/SET_FORM_TYPE', 'EDIT');
      await this.$store.dispatch('billing/form/applyEditData', billing);
      this.$modal.show('modal-billing', {
        billing: billing,
        fromBillingDetail: false,
        dispatchAfterString: 'transaction/getDetailInitData',
      });
    },
    showDeleteDialog(billing) {
      this.selectedBilling = billing;
      this.showDelete = true;
    },
    async deleteBilling() {
      try {
        await this.$store.dispatch('billing/form/applyEditData', this.selectedBilling);
        await this.$store.dispatch('billing/form/delete');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('billing.delete.successMsg'),
          'success',
        );
        this.showDelete = false;
        await this.$store.dispatch('transaction/getDetailInitData', this.$route.params.uuid);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>
